// const serverURL = 'http://localhost:3000/api';
const serverURL = "https://ostproduct.com/api";
// export const serverURL = 'https://grebi-money.ru/api';
// const serverURL = 'http://350044-cq02541.tmweb.ru/api';

//URLS FOR REFERRAL LINKS

// const webURL = 'http://localhost:3001';
const webURL = "https://ostproduct.com";
// export const webURL = 'https://grebi-money.ru';
// const webURL = 'http://350044-cq02541.tmweb.ru';

export { serverURL, webURL };

///THEЕSE ARE MY CHANGES FOR ost AFTER WALL IS SET
